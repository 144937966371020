.border-bottom {
  width: inherit;
  position: absolute;

  &::before {
    position: absolute;
    left: -28px;
    top: 1px;
    content: url('../../../../images/common/lineHead.svg');
  }
  &::after {
    position: absolute;
    content: url('../../../../images/common/lineHead.svg');
    top: 1px;
    right: -28px;
  }
  .line {
    height: 13px;
    width: 100%;
  }
}

.heading-name {
  color: #aa9b46;
  line-height: 24px !important;
  font-size: 32px;
  text-align: center;
  font-family: 'Cinzel', serif;
  font-weight: 5;
  line-height: 130%;
  letter-spacing: 1.5px;
  margin: 8px 0px;
  text-transform: uppercase;
  transform: scale(1, 1.01);
  -webkit-transform: scale(1, 1.01); /* Safari and Chrome */
  -moz-transform: scale(1, 1.01); /* Firefox */
  -ms-transform: scale(1, 1.01); /* IE 9+ */
  -o-transform: scale(1, 1.01); /* Opera */

  line-height: 35px !important;
}

.border-bottom-main {
  position: relative;
  width: 100%;
  margin-bottom: 2.5rem;

  @include max-break-point(tablet-landscape) {
    margin-bottom: 4rem;
  }
}

.plain-sep {
  width: 100%;
  margin: 0 auto;
}

@include max-break-point(tablet-landscape) {
}
@include max-break-point(tablet) {
}
@include max-break-point(mobile-phone) {
  .heading-name {
    font-size: 24px !important;
    line-height: 24px !important;
  }
}
