.plain-decor-wrapper {
  position: relative;
  border: 1px solid var(--primary-color);
  background-color: rgba(3, 3, 3, 0.7);
  .plain-decor-edge-tl,
  .plain-decor-edge-tr,
  .plain-decor-edge-bl,
  .plain-decor-edge-br {
    position: absolute;
    @include disable-selection;
  }
  .plain-decor-edge-tl {
    top: -1px;
    left: 0;
  }
  .plain-decor-edge-tr {
    right: -1px;
    transform: rotate(90deg);
    top: -1px;
  }
  .plain-decor-edge-bl {
    bottom: -1px;
    transform: rotate((270deg));
    left: 0;
  }
  .plain-decor-edge-br {
    bottom: -1px;
    right: -1px;
    transform: rotate(180deg);
  }
}

.plain-decor-content {
  padding: 10px;
}
