.emerald-info-bg {
  // position: relative;
  // left: calc(-50vw + 50%);
  // background-image: url('../../../images/extra/emeraldInfoBg.png');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  // background-position: center center;
  // background-attachment: fixed;
  margin: -10px auto 40px auto;
  // height: 350px;
  display: flex;
  // width: 99.6vw;
  justify-content: center;
  align-items: center;

  @include max-break-point(tablet) {
    margin: -50px auto 40px auto;
  }
}

.emerald-info-wrapper {
  max-width: 72.375rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  gap: 12px;
}

.emerald-info-p {
  font-weight: 400;
  font-size: 15px;
  line-height: 1.7;
  color: #c9ccd9;
  letter-spacing: 0.35px;
}

.stylish-manson-heading {
  width: 55% !important;
}

.stylish-info-seperator {
  width: 55% !important;
  margin-bottom: 0px !important;
}

@include max-break-point(tablet-landscape) {
}
@include max-break-point(tablet) {
  .emerald-info-wrapper {
    margin: 50px auto;
    width: 90%;
  }
  .emerald-info-p {
    margin-top: -55px;
  }
  .stylish-info-seperator,
  .stylish-manson-heading {
    width: 65% !important;
  }
}
@include max-break-point(mobile-phone) {
  .emerald-info-wrapper {
    width: 100%;
  }
  .stylish-info-seperator,
  .stylish-manson-heading {
    width: 83% !important;
  }
  .extra-sm-seperator {
    width: 60% !important;
  }
  .emerald-info-bg {
    margin-top: 90px 0px;
  }
}
