.ledger-address-body {
 .ledger-address-list {
     padding: 20px 0px;

     .address-container {
         padding: 5px 0px;
         text-overflow: ellipsis;
         cursor: pointer;

         .address-text {
             font-size: 16px;

             &:hover {
                 font-weight: bold;
             }
         }

         &.active {
             color: green
         }
     }  
 } 
}

.address-text {
    font-size: 16px;
}  