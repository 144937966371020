.congrat-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  overflow: hidden;
  background-color: #000101;
  z-index: 1;

  .congrat-content-wrapper {
    padding: 0 20px 20px;
  }

  .congrat-header {
    margin-top: 52px;
  }

  .congrat-body {
    width: 75%;
    margin: 25px auto 40px;
    @include max-break-point(mobile-phone) {
      width: 100%;
    }
  }
  .congrat-btns {
    display: flex;
    gap: 12px;
    justify-content: center;
    flex-wrap: wrap;
  }
}
