.land-filter {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    position: relative;
  }

  .land-filter-wrapper {
    background-color: #112E2A;
    width: 300px;
    position: fixed;
    display: flex;
    z-index: 999;
    flex-direction: column;
    justify-content: space-between;
    top: 65px;
    left: 0;
    bottom: 0;
    padding-bottom: 300px;
    transition: 0.4s;
  }


  .land-filter-title {
    font-size: 2rem;
    display: flex;
    justify-content: center;
    padding: 1rem;
    font-weight: bold;
    margin: 10px 0px;
  }

  .filter-wrapper-inside {
    display: flex;
    flex-direction: column;
   gap: 30px;
   height: 100vh;
   overflow: scroll;
   padding-bottom: 200px;
  }

  .filter-header{
    display: flex;
    padding: 0px 30px;
    justify-content: space-between;
    font-size: 20px;
    margin-bottom: 15px;
  }

  .header-icon{
    cursor: pointer;
    scale: 75%;
    border-radius: 50%;
    border: 2px solid rgb(153, 141, 141);
    padding: 5px;
  }

  .header-icon-plus{
    cursor: pointer;
    scale: 70%;
    border-radius: 50%;
    border: 2px solid rgb(153, 141, 141);
    padding: 2px;
  }

  .filtered-content{
    display: flex;
    flex-direction: column;
  }

  .filter-body{
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
  }

  .label{
    cursor: pointer;
  }

  .horizontal-line{
    margin-top: 20px;
  }

  .apply-btn {
    margin: 0 10%;
    z-index: 1;;
    top: 85%;
    left: 0%;
    box-sizing: border-box;
  }

  .filter-close-icon{
    scale: 200%;
    margin-left: 100px;
    position: absolute;
    top: 20px;
    left: 170px;
    color: #bcece6;
    cursor: pointer;
    display: none;
  }

  .filer-btn-wrapper{
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .filter-toggle-btn{
    cursor: pointer;
  }


  @media (max-width: 1730px) {
    .filter-close-icon{
      display: block;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 991px) {
    .land-filter-wrapper {
      top: 120px //65
    }
  }

  @media (max-width: 842px) {
    .filter-close-icon{
      display: block;
    }
  }


  .loading-skeleton{
    width: 80%;
    height: 35px;
    margin: -10px 10%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    opacity: 0.2
  }

  .sub-skeleton-wrapper{
    display: flex;
    flex-direction: row;
  }

  .skeleton-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -10px 10%;
    opacity: 0.2
  }

  .sk-subtitile{
    width: 70%;
    height: 10px;
    border-radius: 4px;
    opacity: 0.4
  }

  .sk-checkbox{
    width: 7%;
    height: 10px;
    border-radius: 4px;
    opacity: 0.4
  }


  .skeleton{
    animation: skeleton-loading 1s linear infinite alternate;
  }
  
  .land__not__found {
    margin: auto;
    font-size: 25px;
    font-weight: 500 !important;
    color: rgb(163, 153, 153);
    margin-top: 40%;
    min-height: 20vh;
  }

  .filter-close-btn-wrapper{
    position: relative;
  }

  @keyframes skeleton-loading{
    0%{
      background-color: #5eecd9c4;
    }

    100%{
      background-color: #42e0cb79;
    }
  }


  .all-lands-outer-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 99%;
  }

 .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #F5DE94;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 4px;
  top: 1px;
  width: 3px;
  height: 7px;
  border: solid rgb(87, 87, 87);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}