.summary-cards {
  margin: 102px 0px 42px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;

  .card-wrapper {
    width: 250px;
    height: 300px;

    .property-image {
      margin-top: -20px;
      max-width: 100%;
      height: 250px;
      object-fit: contain;
    }

    .property-info-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      font-family: var(--manrope);
      text-align: center;
      margin: -20px 0 10px;

      .property-name-text {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.0125em;
        text-transform: uppercase;
        color: #c9ccd9;
      }

      .zone-type-text {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.015em;
        color: rgba(201, 204, 217, 0.6);
        text-transform: uppercase;
      }

      .property-detail-link {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.004em;
        color: var(--primary-color);
        margin-top: 4px;
        text-decoration: none;
      }
    }
  }
}
