.transaction-bg {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(24px);
}
.trade-transaction-wrapper {
  .trade-transaction-title {
    p {
      color: var(--primary-color);
    }
  }
  .trade-transaction-details {
    overflow-x: auto;
    // @include changeScrollBar;
    // width: 00px;
    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      th,
      td,
      tr {
        font-weight: 400;
        color: #dedede;
        width: 150px;
      }
      th {
        font-size: 12px;
      }
      tr {
        font-size: 14px;
      }
      th,
      td {
        text-align: left;
        padding: 8px;
      }
      tr:nth-child(even) {
        background-color: rgba(170, 155, 70, 0.1);
        padding: 5px;
      }
      .transaction_score {
        color: #079b9d;
        cursor: pointer;
        .copy_transaction_score {
          display: flex;
          gap: 10px;
        }
      }
    }
  }
}

// @include max-break-point(tablet-landscape) {
//   .trade-transaction-details {
//     overflow-x: auto;
//     @include changeScrollBar;
//   }
// }
// @include max-break-point(tablet) {
// }
// @include max-break-point(mobile-phone) {
// }
