.crown__wrap {
  .sub__heading__crown {
    text-align: center;
    margin-top: 10px;
    .r-body-1 {
      font-size: 18px !important;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
  .crown__desc {
    text-align: center;
    color: #c9ccd9;

    p {
      line-height: 1.6em;
    }

    a {
      text-decoration: none;
      color: var(--primary-color);
    }
  }

  .crown__earning__sources {
    @include max-break-point(tablet) {
      width: 100%;
    }

    width: 70%;
    backdrop-filter: blur(24px);
    background: rgba(0, 0, 0, 0.6);
    margin: 20px auto;
    display: flex;
    justify-content: center;
    text-align: center;

    .r-body-1 {
      font-size: 18px !important;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  .crown__source__item {
    .crown__source__top {
      @include max-break-point(tablet) {
        flex-direction: column;
      }
      display: flex;
      margin: 30px 0;
      .crown__source__name {
        &:nth-child(1),
        &:nth-child(2) {
          align-self: center;
        }
        a {
          color: var(--primary-color);
          text-decoration: none;
        }
        flex: 1;
        align-self: top;
        text-align: left;

        @include max-break-point(tablet) {
          align-self: center;
          text-align: center;
        }
      }

      .crown__source__total {
        @include max-break-point(tablet) {
          text-align: center;
          margin: 10px 0;
        }

        flex: 1;
        text-align: right;
        font-weight: 600;
        font-size: 24px;

        img {
          margin-right: 10px;
          width: 20px;
        }

        span {
          &:nth-child(3) {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 1px;
          }
        }

        .crown__show__details {
          color: #068385;
          font-size: 12px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }

    .crown__source__bottom {
      font-weight: 400;
      letter-spacing: 0.25px;
      text-align: left;
      line-height: 1.6;
      color: #ffffffd6;
    }
  }
}

.link {
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
  color: #068385;
  margin: 10px 0 24px 0;
  display: inline-block;
}

.ac-overview {
  width: 100%;
  border-radius: 10px;
  margin: 1rem 0;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .wd-bal-overview {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(24px);
    flex: 1;
    border-radius: 10px;

    .wd-info {
      b,
      .info__icon {
        color: var(--primary-color);
      }
      @include max-break-point(tablet-landscape) {
        margin: 1.5em 0;
      }

      @include max-break-point(mobile-phone) {
        margin: -10px;
      }
    }
  }

  .bal-amt-ov {
    font-weight: 600;
    font-size: 24px;
    margin-top: 14px;

    img {
      margin-right: 10px;
      width: 20px;
    }

    span {
      &:nth-child(3) {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
      }
    }
  }

  .available__balance-overview {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(24px);
    flex: 1;
    display: flex;
    // flex-direction: column;
    // align-items: center;

    .available__balance-content {
      @include max-break-point(mobile-phone) {
        flex-direction: column;
        margin: -10px;
      }

      display: flex;
      gap: 20px;

      .balance__flex {
        flex: 1;
      }

      .btn__claim {
        flex: 1;
      }

      .disabled:hover {
        cursor: not-allowed !important;
      }

      b,
      .info__icon {
        color: var(--primary-color);
      }
    }

    .withdraw-btn-available__balance {
      flex: 1;
      align-self: center;

      @include max-break-point(mobile-phone) {
        width: 100%;
      }

      .view__claim__history {
        justify-content: center;
        margin-top: 16px;
        color: var(--primary-color);
        font-weight: 600;
        display: flex;
        gap: 7px;
        align-items: center;
        background: none;
        border: none;
        width: 100%;
      }

      .disabled {
        color: silver !important;
      }

      .view__claim__history:hover {
        cursor: pointer;
      }
    }

    @include max-break-point(tablet-landscape) {
      flex-direction: column;
    }

    @include max-break-point(mobile-phone) {
      margin: 0;
      align-items: flex-start;
    }
  }

  @include min-break-point(tablet) {
    flex-direction: row;
  }

  @include max-break-point(tablet-landscape) {
    align-items: stretch;
  }
}

.crown__breakdown {
  b {
    text-align: center;
    display: block;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  .individual__breakdown {
    display: flex;
    margin: 10px;
    padding: 4px 15px;

    div:nth-child(2n + 1) {
      text-align: left;
      flex: 1;
    }

    div:nth-child(2n) {
      text-align: right;
      flex: 1;
    }

    &:nth-child(2n) {
      background-color: rgba(170, 155, 70, 0.1);
      
    }
  }
}

.claim__box {
  text-align: center;

  .amt__wrap {
    display: flex;
    gap: 10px;
    align-items: baseline;
    justify-content: center;
  }

  span {
    font-family: var(--manrope);
  }

  img {
    margin-top: 20px;
  }

  .crown-loader {
    font-family: var(--cinzel);
    color: var(--primary-color);
    font-size: 24px;
  }

  .crown-loader-amount {
    font-family: var(--manrope);
    font-size: 28px;
  }
}
