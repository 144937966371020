.roi-calculator-wrapper {
  .roi-amount-div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .roi-token {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #c9ccd9;
    }
    .roi-amount {
      background: #242129;
      border: 2px solid #ddbf62;
      position: relative;
      .roi-amount-units {
        display: flex;
        align-items: center;
        gap: 6px;
        .input-amount {
          width: 70%;
          height: 50px;
          background: none !important;
          border: none !important;
          color: #e5e8eb;
          text-align: right;
          font-weight: 700;
          font-size: 16px;
          &:focus {
            outline: none;
          }
        }
        .roi-unit {
          text-transform: uppercase;
          color: #e5e8eb;
          font-weight: 700;
          font-size: 16px;
        }
      }
      .switch-icon {
        position: absolute;
        transform: rotate(90deg);
        right: 10px;
        top: 20px;
        color: #5b5f65;
        cursor: pointer;
      }
      .calculated-amt {
        font-weight: 600;
        font-size: 10px;
        line-height: 0px;
        text-align: center;
        padding-bottom: 15px;
        letter-spacing: 0.004em;
        color: #5b5f65;
      }
    }
  }
  .recommended-calc-roi {
    display: flex;
    // justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
    .roi-key-amount {
      border: 1px solid #ddbf62;
      color: #ddbf62;
      padding: 0px 10px;
      cursor: pointer;
      font-size: 10px;
    }
  }
  .roi-stake {
    margin-top: 20px;
    .stake-roi-text {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #c9ccd9;
    }
    .stake-roi-day {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      .stake-day-for {
        border: 1px solid #ddbf62;
        color: #ddbf62;
        padding: 5px 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
    .current-rate-div {
      margin-top: 15px;
      .roi-current-rate {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .roi-current-rate-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 160%;
          letter-spacing: 0.5px;
          color: #aa9b46;
        }
        .roi-current-amt-usd {
          font-weight: 700;
          font-size: 20px;
          line-height: 160%;

          text-align: right;
          letter-spacing: 0.15px;

          color: #ffffff;
        }
        .roi-current-amt-crown-apx {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;

          letter-spacing: 0.004em;

          color: #ffffff;
        }
      }
    }
  }
}
