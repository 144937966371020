.marketplace-filter {
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
}

.filter-text {
  color: #c9ccd9;
}

#filterProperty,
#sortBy {
  cursor: pointer;
  border: 1px solid var(--primary-color);
  color: #c9ccd9;
  background: #242129;
  width: 164px;
  height: 34px;
  padding: 6px 8px 6px 12px;

  &:focus {
    outline: none;
  }
}

.marketplace-lands {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  gap: 30px;
  //   justify-content: space-between;
}

.no_result_found {
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
