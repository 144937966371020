.carousel-controls {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 38.5%;
  width: 100%;

  @include max-break-point(tablet) {
    top: 37.5%;
  }
  @include max-break-point(mobile-phone) {
    top: 32.5%;
  }

  .carousel-control {
    position: absolute;

    &.left {
      left: 7px;
    }

    &.right {
      right: 7px;
      transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
    }

    &:hover {
      .animate__control-bg {
        background-color: #ddbf6275;
        border-radius: 50%;
        animation: heartbeat 800ms;
      }
    }
    img {
      position: absolute;
      top: 10px;
    }
    .carousel-next-left {
      left: 8px;
    }
    .carousel-next-right {
      right: -35px;
    }
  }

  .animate__control-bg {
    position: absolute;
    width: 50px;
    height: 50px;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
