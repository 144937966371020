.certificate-wrapper {
  position: relative;

  .certificate-content {
    .certificate-subtitle {
      font: normal 600 12.87px/21px var(--manrope);
      white-space: pre-wrap;
      text-align: center;
      letter-spacing: 2.29846px;
      text-transform: uppercase;
      color: #9c9c9c;
      height: 42px;

      span {
        color: #ddbf62;
      }

      position: absolute;
      bottom: 45px;
      left: 0;
      right: 0;
      margin: 0 50px;

      @include max-break-point(mobile-phone) {
        bottom: 28px;
        margin: 0 32px;
        font-size: 10px;
      }
    }
  }
}
