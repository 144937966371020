//forex live soon css
.forexLiveSoon-wrapper {
  background-image: url('../../../images/extra/forex.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  backdrop-filter: blur(24px);

  @include max-break-point(tablet-landscape) {
    background-image: none;
    backdrop-filter: blur(24px);
  }

  .forex-live-text-div {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    .forex-live-soon-text {
      font-family: 'Cinzel', serif;
      color: #aa9b46;
      font-weight: 5;
      font-size: 30px;
      line-height: 110%;
      text-transform: uppercase;
    }
    .forex-live-soon-redirect {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 600;
      cursor: pointer;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.004em;
      color: #068385;
    }
  }
}

@include max-break-point(tablet-landscape) {
  .forex-live-text-div {
    height: 45vh !important;
  }
}

@include max-break-point(tablet) {
  .forex-live-text-div {
    height: 35vh !important;
  }
}
@include max-break-point(mobile-phone) {
  .forex-live-text-div {
    height: 25vh !important;
  }
  .forex-live-soon-text {
    font-size: 16px !important;
  }
  .forex-live-soon-redirect {
    font-size: 10px !important;
    line-height: 16px;
  }
}
