.dot-wrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: flex-start;
  justify-content: center;
  padding: 8px 12px;
  margin: 17px auto 0px;

  background: rgba(54, 54, 54, 0.5);
  border-radius: 47px;

  .carousel-dot {
    width: 8px;
    height: 8px;
    background: var(--primary-color);
    opacity: 0.2;
    border-radius: 50%;

    &.active-dot {
      opacity: 1;
    }
  }
}
