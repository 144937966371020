.property-location-detail {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 18px;
  font: normal 400 12px/16px var(--manrope);
  letter-spacing: 0.5px;

  .location {
    color: #e5e8eb;
    text-transform: capitalize;
    white-space: break-spaces;
  }
  div {
    display: flex;
    width: 140px;
    padding: 8.211px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8.211px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 8px;
    background: rgba(71, 71, 71, 0.4);
    text-align: center;
  }
}
