.card-wrapper {
  width: 247px;
  // height: 355px;
  // margin-bottom: 50px;
  .card-certificate {
    height: 237px;
    border: 1px solid var(--primary-color);
    img {
      width: 100%;
    }
  }
}
.card-type-certificate-text {
  color: #c9ccd9;
}
.card-type-text {
  color: var(--primary-color);
}
.card-icx-amout {
  color: #079b9d;
}
.card-decor-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .card-type {
    display: flex;
    flex-direction: column;
    p {
      text-align: center;
    }
    align-items: center;
  }
}

.buy-btn {
  // color: var(--primary-color);
  // border: 2px solid var(--primary-color);
  // cursor: pointer;

  color: #c0c0c0;
  border: 2px solid #c0c0c0;
  cursor: no-drop;

  padding: 2px 25px;
  text-transform: uppercase;
  background-color: transparent;
}
.card-bottom {
  display: flex;
  justify-content: space-between;
  .card-icx {
    display: flex;
    align-items: center;
    gap: 7px;
  }
}
