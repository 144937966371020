.land-reveal-page {
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background: #00000099;

  .land-reveal-wrapper {
    .land-reveal-header {
      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 160%;
        letter-spacing: 0.15px;
        color: #e5e8eb;
      }

      .sub-title {
        font: normal 400 14px/22px var(--manrope);
        letter-spacing: 0.0025em;
        color: #c9ccd9;
        margin-bottom: 24px;
      }
    }

    .carousel-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .general-certificate {
        img {
          width: 500px;
        }
      }
    }
    .reveal-amt-wrapper {
      @include flexbox;
      flex-direction: column;
      align-items: center;
      margin: 20px 0;
      gap: 8px;

      .r-subtitle-2 {
        color: var(--primary-color);
      }

      .reveal-amt-subtitle {
        font: normal 600 12px/16px var(--manrope);
        color: #c9ccd9;
        letter-spacing: 0.004em;
      }
      .reveal-amt-counter {
        @include flexbox;
        justify-content: center;
        align-items: center;
        gap: 30px;
      }

      .reveal-property-btn .button {
        @include max-break-point(mobile-phone) {
          width: 320px !important;
        }
      }
    }
  }
  .skeleton-certificate {
    width: 533px;
    height: 355px;
    margin: 0 auto;

    @include max-break-point(mobile-phone) {
      width: 318px;
      height: 214px;
    }
  }

  .no-certificate-found {
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0 10px;
    p {
      font-size: 25px;
      font-weight: 700;

      @include max-break-point(mobile-phone) {
        font-size: 19px;
        text-align: center;
      }
    }
  }

  .reveal-btn-wrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: center;
  }
}
