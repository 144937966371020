@import './fonts.styles';
@import './variable.styles';
@import './typescale.style';
@import './mixins';

:root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // font-size: 100%;
}

body {
  margin: 0;
  background-color: #141414;
  background-image: url('../../images/common/bg_own.png');
  background-repeat: no-repeat;
  background-position: fixed;
  background-attachment: fixed;
  // background-position: center center;
  background-size: cover;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  overflow-x: hidden;
}

.parent-container {
  max-width: 90rem !important;
  min-width: 32.5rem !important;
  margin: 0 auto !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: #373737;
}

::-webkit-scrollbar-thumb {
  background: #079b9d;
}

// ::-webkit-scrollbar-thumb:hover {
//   background: #079b9d;
// }

::-moz-selection {
  color: var(--white);
  background: var(--primary-color);
}
::selection {
  color: var(--white);
  background: var(--primary-color);
}

img {
  @include disable-selection;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}
.skeleton-default-width {
  width: 100%;
  height: 100%;
}

@keyframes skeleton-loading {
  0% {
    background-color: #4b4b4b;
  }
  100% {
    background-color: #242424;
  }
}
// @include max-break-point(tablet-landscape) {
//   :root {
//     font-size: 75%;
//   }
// }
// @include max-break-point(tablet) {
//   :root {
//     font-size: 65%;
//   }
// }
// @include max-break-point(mobile-phone) {
//   :root {
//     font-size: 40%;
//   }
// }
