.mint-main-bg {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(24px);
  margin: auto;
  width: 95% !important;

  @include max-break-point(tablet-landscape) {
    backdrop-filter: none;
    width: 100%;
  }
}

.minted__info__wrap {
  display: flex;
  justify-content: center;
}

.minted__bg {
  @include max-break-point(mobile-phone) {
    width: 90% !important;
  }
}

.minted__info {
  display: flex;
  justify-content: center;
  margin: 20px auto;
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .info__mintCount {
    color: #aa9b46;
    line-height: 32px !important;
    font-size: 28px;
    text-align: center;
    font-family: 'Cinzel', serif;
    font-weight: 5;
    margin: 16px 0px;

    @include max-break-point(tablet-landscape) {
      line-height: 35px !important;
    }
  }

  .minted_info_text {
    font-size: 14px !important;
    margin-bottom: 3px;
  }
}

.mint-wrapper {
  display: flex;
  gap: 50px;
  position: relative;

  @include max-break-point(mobile-phone) {
    gap: 20px;
  }

  .mint__counter_wrapper {
    .mint__method__select {
      .mint__amount__select {
        color: var(--primary-color);
      }

      .mint__bottom__info {
        color: var(--primary-color);
        font-size: 14px;
        font-weight: 600;
      }

      .mint__choose__token__wrap {
        display: flex;
        gap: 20px;

        @include max-break-point(mobile-phone) {
          gap: 5px;
        }

        p {
          font-family: var(--manrope) !important;
        }

        .crown__mint {
          min-width: 156px;

          &.crown {
            outline: 2px solid var(--primary-color);
          }
          div {
            gap: 26px !important;
          }
        }

        .bribe__mint {
          &.bribe {
            outline: 2px solid var(--primary-color);
          }
        }

        div {
          flex: 1;
          // height: 180px;
          height: 140px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          gap: 10px;

          p {
            font-size: 14px;
            color: var(--primary-color);
            font-family: 'Cinzel', serif;
            text-align: center;
            padding-top: 8px;
            font-weight: 500;
            letter-spacing: 0.35px;
          }

          .crown__img {
            width: 40px;
            margin-bottom: 5px;
          }

          .bribe__img {
            width: 60px;
          }

          .images__coll {
            display: flex;
            flex-direction: row;

            .crown__img {
              margin-right: 5px;
            }
          }
        }

        div:hover {
          cursor: pointer;
        }
      }
    }
  }

  .mint-Certificate {
    display: flex;
    justify-content: center;
    flex: 2;
    width: 100%;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to top, rgba(0, 0, 0, 0), var(--primary-color), rgba(0, 0, 0, 0))
      1 100%;
    border-left: none;
    padding-right: 40px;

    img {
      max-width: 470px;
    }

    @include max-break-point(mobile-phone) {
      padding-right: 0px;
    }
  }
  .mint-box {
    flex: 1.4;
    margin-top: 20px;
    width: 100%;
    .h6 {
      text-align: center;
      line-height: 0px !important;
    }

    .help__mint__btn {
      background: transparent;
      color: white;
      border: none;
    }

    .help__mint__btn:hover {
      cursor: pointer;
    }
  }

  .mint-plain-mid-sep {
    position: absolute;
    top: 4%;
    left: 56%;
  }
}

.mint-counter-box {
  .undo__btn {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    color: white;
    font-size: 20px;
    border: none;
  }

  .undo__btn:hover {
    cursor: pointer;
    animation: jiggle 0.5s infinite linear;
  }

  @keyframes jiggle {
    /* start at 0deg and this scale size.
       scale size is repeated here to maintain smooth scaling */
    0% {
      transform: rotate(0deg) scale(1.15, 1.15);
    }
    /* midway rotate 2deg right */
    50% {
      transform: rotate(2deg) scale(1.15, 1.15);
    }
    /* end rotate -2deg left */
    100% {
      transform: rotate(-2deg) scale(1.15, 1.15);
    }
  }

  @include flexbox;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  position: relative;

  .mint-incr-decr {
    @include flexbox;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
}

.mint-count {
  @include disable-selection;
}

.mint-amounter {
  @include flexbox;
  justify-content: space-between;
  flex-direction: row;

  @include max-break-point(mobile-phone) {
    flex-direction: column;
    gap: 20px;
  }

  .mint-uprice {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .mint-total-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 16px;
  }

  .price-per-mint,
  .total-price {
    align-self: center;
    font-size: 17px;
    font-weight: 500;
    color: var(--primary-color);
  }
  .total-price-amt,
  .price-per-mint-amt {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    display: flex;
    gap: 10px;
    align-items: baseline;
  }
}

.mint-minus,
.mint-plus {
  cursor: pointer;
}

//minting live soon css
.mintingLiveSoon-wrapper {
  &.bg-enabled {
    background-image: url('../../../images/common/certificate_test.jpg');
  }
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 85% !important;
  height: 350px !important;
  margin: auto !important;
  backdrop-filter: blur(24px);
  // height: 50vh;

  @include max-break-point(tablet) {
    background-image: none;

    backdrop-filter: blur(24px);
    width: 90% !important;
    margin-top: -25px;
  }

  .minting-live-text-div {
    height: 310px !important;
    margin: auto;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    font-size: 31px;

    .minting-live-soon-text {
      font-family: 'Cinzel', serif;
      color: #aa9b46;
      font-weight: 5;
      font-size: 30px !important;
      line-height: 110%;
      text-transform: uppercase;
    }
    .minting-live-soon-redirect {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      line-height: 16px;
      letter-spacing: 0.004em;
      color: #068385;
    }
  }
}
.sold-out {
  display: flex;
  justify-content: center;
  .sold-out-text {
    margin-bottom: 20px;
  }
}

.bribe-minus,
.bribe-plus {
  border: none;
  cursor: pointer;
}

@include max-break-point(tablet-landscape) {
  .minting-live-text-div {
    height: 45vh !important;
  }

  .mint-amounter {
    flex-direction: column;
  }
}
@include max-break-point(tablet) {
  .mint-wrapper {
    flex-direction: column;

    .mint-Certificate {
      border: none;
      padding-left: 40px;
    }
  }

  .mint-amounter {
    flex-direction: row;
  }
}

@include max-break-point(mobile-phone) {
  .mint-wrapper {
    flex-direction: column;

    .mint-Certificate {
      padding-left: 0px;
    }
  }

  .mint-amounter {
    flex-direction: column;
  }
}

@include max-break-point(tablet) {
  .minting-live-text-div {
    height: 35vh !important;
  }
}

.bribe__listings {
  @include max-break-point(mobile-phone) {
    margin: -40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .bribe__info {
    display: flex;
    padding: 20px 30px 0px 30px;
  }
  .bribe__details {
    display: flex;
    flex: 1;
    flex-direction: row;

    .bribe__img {
      img {
        width: 50px;
      }
    }

    .bribe__other__desc {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      .bribe__name {
        color: var(--primary-color);
      }
    }
  }

  .bribe__count {
    display: flex;
    gap: 10px;
    align-items: baseline;
    margin-top: 27px;

    button {
      background: transparent;
      color: white;
    }
  }
}

@include max-break-point(mobile-phone) {
  .minting-live-text-div {
    height: 25vh !important;
  }
  .minting-live-soon-text {
    font-size: 16px !important;
  }
  .minting-live-soon-redirect {
    font-size: 10px !important;
    line-height: 16px;
  }
}

.golden-key-check {
  display: block;
  margin: auto;
  text-align: center;
}
