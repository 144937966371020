@import './common/index.style.scss';
@import './components/index.styles.scss';
@import './pages/index.style.scss';

/* Both the CSS of pages and components rests under component directory. */

.d-flex {
  display: flex !important;
}
.bg-backdrop {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(24px);
}

.emeraldcity-leaflet-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 80px;
}

.emeraldcity-leaflet-wrapper {
  width: 100%;
  height: 500px;
  overflow: hidden;
  margin: 15px 0px;
}

.emeraldcity-leaflet-btn {
  width: 215px !important;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}
.leaflet-div-icon {
  background-color: transparent !important;
  border: none !important;
}
.district_name_label {
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
  letter-spacing: 0.004em;
  text-transform: uppercase;

  color: #ffffff;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
.emerald_city_map_container {
  width: 100%;
  height: 500px;
  overflow: 'hidden';
  margin: 15px 0px;
  position: relative;
}
