.buySales-profile-container {
  .buySale-bg {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(24px);
  }
  .not-for-sale {
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: 0.0156rem;
    // text-transform: uppercase;
  }
  .owner-setForSale-component {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .setForSale-btn {
      display: flex;
      flex-direction: column;
      gap: 7px;
    }
  }
  .transfer-btn {
    color: var(--primary-color);
    text-transform: uppercase;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.0125em;
  }
}
.model-note {
  font-size: 12px;
  line-height: 3px;
}

.on_sale {
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
  .on_sale_left_info {
    display: flex;
    flex-direction: column;
    // gap: 10px;
    .on_sale_current_price_text {
      color: var(--primary-color);
    }
    .on_sale_amount_info {
      display: flex;
      flex-direction: column;
      position: relative;
      gap: 1px;
      .on_sale_usd {
        position: absolute;
        top: 40px;
        small {
          margin-left: 30px;
          font-weight: 300;
        }
      }
      .on_sale_icx {
        display: flex;
        gap: 7px;
        small {
          align-self: flex-end;
          margin-bottom: 10px;
          font-weight: 300;
        }
        .on_sale_icx_amount {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 160%;
          letter-spacing: 0.15px;
          color: #ffffff;
        }
      }
    }
  }
  .on_sale_right_btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
  .buy_loc_btn {
    align-self: center;
  }
}

@include max-break-point(tablet-landscape) {
  .on_sale {
    flex-direction: column;
    //   justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  .owner-setForSale-component {
    flex-direction: column;
    gap: 20px;
  }
}
@include max-break-point(mobile-phone) {
}
