.bribe__desc {
  text-align: center;
  color: #c9ccd9;
}

.tale__card__wrap {
  display: flex;
  width: 100%;
  justify-content: center;

  .tale__card {
    width: 65%;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(24px);
    border: 1px solid var(--primary-color);
    margin: 2em 0;
    padding: 24px 36px;

    @include max-break-point(tablet) {
      width: 85%;
    }

    @include max-break-point(mobile-phone) {
      width: 95%;
    }

    .tale__card__author {
      display: flex;
      flex-direction: row;
      width: 100%;

      .tale__card__author__avatar {
        img {
          border-radius: 50%;
          height: 45px;
          width: 45px;
        }
      }
      .tale__card__auth_minor_wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        padding: 5px;
        width: 35%;
        padding-left: 10px;
      }
      .tale__card__author__name {
        color: var(--primary-color);
      }
      .tale__card__author__address {
        font-size: 0.8em;
      }
      .tale__card__txHash {
        width: 55%;
        text-align: end;
        font-size: 10px;
        color: rgba(231, 231, 231, 0.6);
      }
    }

    .tale__card__picture {
      display: flex;
      //   flex-direction: column;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 400px;
        margin: 14px 0;
        overflow: none;
      }
    }

    .tale__card__desc {
      color: #c9ccd9;
      font-size: 14px;
      line-height: 150%;
      text-align: left;
    }

    .tale__card__bribe {
      display: flex;
      .tale__card__bribe__picture {
        img {
          max-width: 40px;
          max-height: 40px;
          margin: 14px 0;
          overflow: none;
          margin-right: 10px;
        }
      }
    }

    .tale__card__footer {
      display: flex;
      flex-direction: row;
      margin-top: 10px;

      .tale__card__twitter__logo {
        width: 50%;
        text-align: left;
        font-size: 16px;

        a {
          text-decoration: none;
          color: rgba(231, 231, 231, 0.6);
        }
      }

      .tale__card__timestamp {
        width: 50%;
        text-align: right;
        font-size: 12px;
        color: rgba(231, 231, 231, 0.6);
      }
    }
  }
}
