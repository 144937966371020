.main-wrapper {
  background-color: rgba(0, 0, 0, 0.4);
  .connect-success {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    .wallet-connected-success {
      font-size: 20px;
      font-weight: 300;
      letter-spacing: 0.1px;
    }
    .wallet-connected-address {
      color: #079b9d;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .disconnect-wallet {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    .confirm-cancel-btns {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  @include max-break-point(tablet-landscape) {
    background-color: #1d1d1d;
    padding: 25px;
  }
}

.header-logo {
  img {
    width: 185px;
  }
}

.main-header {
  @include max-break-point(tablet-landscape) {
    gap: 10px;
  }
  // overflow: hidden; //todo to be fixed later
  max-width: 90rem;
  margin: auto;
  @include flexbox;
  padding: 0px 24px;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  // justify-content: center;
  .header-content {
    @include flexbox;
    align-items: center;
    gap: 30px;
    .header-item {
      align-self: center;
      .links {
        color: var(--primary-color);
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.3px;
      }
    }
  }
}

.header-crown {
  @include flexbox;
  gap: 5px;
  align-items: center;
  .header-crown-text {
    font-size: 12px;
    font-weight: 600;
    line-height: 16.39px;
    letter-spacing: 0.4%;
  }
}

.header-wallet-connection {
  position: relative;
  .header-wallet-conn-options {
    position: absolute;
    border: 1px solid var(--primary-color);
    padding: 1px 10px;
    top: 45px;
    width: inherit;
    z-index: 15;
    background-color: #212529;
    .con-btn-header {
      width: 130px;
      height: 28px;
      text-align: left;
      color: white;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
    &::after {
      content: ' ';
      position: absolute;
      border: 5px solid transparent;
      border-bottom: 5px solid var(--primary-color);
      top: -11px;
      right: 50%;
    }
  }
}

.crown-key-wrapper {
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
}

.header-boxes {
  cursor: pointer;
  align-self: center;
  @include flexbox;
  justify-content: center;
  align-items: center;
  width: 132px;
  background-color: #24212999;
  padding: 6px;
  // border: 1px solid var(--primary-color);
  gap: 5px;
  .header-map-box {
    cursor: pointer;
    color: #8d9096;
  }
  .header-icon-box {
    color: #079b9d;
  }
}

.header-show-map {
  // filter: blur(0.8px);
  border: none;
  cursor: default;
  @include disable-selection;
  background-color: linear-gradient(to top right, var(--grad)) top right,
    linear-gradient(to top left, var(--grad)) top left,
    linear-gradient(to bottom right, var(--grad)) bottom right,
    linear-gradient(to bottom left, var(--grad)) bottom left, transparent;
}
.active-class {
  height: 1px;
  margin: 50px 0;
  background: -webkit-gradient(
    linear,
    0 0,
    100% 0,
    from(rgba(0, 0, 0, 0)),
    color-stop(0.5, #333333),
    to(rgba(0, 0, 0, 0))
  );
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), #333333, rgba(0, 0, 0, 0));
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0), #333333, rgba(0, 0, 0, 0));
  background: -o-linear-gradient(left, rgba(0, 0, 0, 0), #333333, rgba(0, 0, 0, 0));
  background: linear-gradient(left, rgba(0, 0, 0, 0), #333333, rgba(0, 0, 0, 0));
  border: 0;

  &:after {
    display: block;
    content: '';
    height: 3px;
    background-image: -webkit-gradient(
      radial,
      50% 0%,
      0,
      50% 0%,
      116,
      color-stop(0%, var(--primary-color)),
      color-stop(100%, rgba(255, 255, 255, 0))
    );
    background-image: -webkit-radial-gradient(
      center top,
      farthest-side,
      var(--primary-color) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-image: -moz-radial-gradient(
      center top,
      farthest-side,
      var(--primary-color) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-image: -o-radial-gradient(
      center top,
      farthest-side,
      var(--primary-color) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-image: radial-gradient(
      farthest-side at center top,
      var(--primary-color) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.hamburger {
  height: 20px;
  display: none;
}

.header-body-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.25%;
}

@include max-break-point(tablet-landscape) {
  .main-wrapper {
    .main-header {
      flex-direction: column;
    }
  }
}
@include max-break-point(tablet) {
  .main-wrapper {
    padding: 0px;
    .main-header {
      flex-direction: row;
    }
  }
  .header-content-menu-deactivate {
    display: none !important;
  }
  .crown-key-wrapper {
    order: -1;
  }
  .header-content-menu {
    animation-name: menu-bar;
    animation-duration: 0.04s;
    display: flex !important;
    position: relative;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    width: inherit;
    position: absolute;
    top: 95px;
    width: 100%;
    background: #1d1d1d;
    // -webkit-backdrop-filter: blur(24px);
    // backdrop-filter: blur(24px);
    margin: -25px;
    padding: 15px;
  }
  .hamburger {
    display: block;
    cursor: pointer;
  }
  .show__menu {
    display: block;
  }
  .header-crown-text {
    font-size: 14px;
  }
}
@keyframes menu-bar {
  0% {
    height: 0px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 296.56px;
  }
}
