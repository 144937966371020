.inventory-type-num {
  // height: 24px;
  background: rgba(54, 54, 54, 0.5);
  border-radius: 47px;
  // width: 100px;
  color: #8d8d8d;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 5px;
  padding: 2px 10px;
}
