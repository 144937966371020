.inventory-stylish-decor {
  margin-top: 20px;
  min-height: 40vh;
}

.filter-tab-inventory {
  display: flex;

  margin: 4% 30px -25px 30px;
  justify-content: space-between;

  .filter-inventory {
    display: flex;
    gap: 30px;
  }

  .button-component {
    top: -9px;
  }
}

@include min-break-point(tablet) {
  .desk-view-craft-link-btn {
    display: none;
  }
}

@include max-break-point(tablet) {
  .mob-view-craft-link-btn {
    display: none;
  }
}

.certificate-card-wrapper,
.bribe-card-wrapper {
  cursor: pointer;
}

.inventory-tab-left {
  clip-path: polygon(
    10px 0%,
    calc(100% - 0px) 0%,
    100% 10px,
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    10px 100%,
    0% calc(100% - 0px),
    0% 10px
  );
}
.tab-inventory {
  z-index: 0;
  position: relative;
  top: -3px;
  display: flex;
  button {
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
    width: 165px;
    height: 43px;
    color: #1d1b21;
    font-family: var(--manrope);
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
  }
}

.inventory-tab-right {
  clip-path: polygon(
    0px 0%,
    calc(100% - 10px) 0%,
    100% 10px,
    100% calc(100% - 0px),
    calc(100% - 0px) 100%,
    0px 100%,
    0% calc(100% - 0px),
    0% 0px
  );
}

.certificate-selected {
  --grad: transparent 49%, var(--primary-color) 52%;
  // background: linear-gradient(to top right, var(--grad)) top right,
  //   linear-gradient(to top left, var(--grad)) top left,
  //   linear-gradient(to bottom right, var(--grad)) bottom right,
  //   linear-gradient(to bottom left, var(--grad)) bottom left, transparent;
  background: linear-gradient(180deg, #ddbf62 -7.14%, #887635 100%);
  background-repeat: no-repeat;
  background-origin: border-box;
  // border: 2.5px solid var(--primary-color);
  border-image: linear-gradient(180deg, #ddbf62 -7.14%, #887635);
}
.bribe-selected,
.property-selected {
  --grad: transparent 49%, var(--primary-color) 52%;
  // background: linear-gradient(to top right, var(--grad)) top right,
  //   linear-gradient(to top left, var(--grad)) top left,
  //   linear-gradient(to bottom right, var(--grad)) bottom right,
  //   linear-gradient(to bottom left, var(--grad)) bottom left, transparent;
  background: linear-gradient(180deg, #ddbf62 -7.14%, #887635 100%);
  background-repeat: no-repeat;
  background-origin: border-box;
  // border: 2.5px solid var(--primary-color);
  border-image: linear-gradient(180deg, #ddbf62 -7.14%, #887635);
}

.bribe-unSelected {
  // color: #aa9b46 !important;
  // font-weight: 600;
  --grad: transparent 49%, var(--primary-color) 52%;
  background: linear-gradient(to top right, var(--grad)) top right;
  // background-size: 15px 14px; /* 10px of the clip-path + 3px of border */
  // background-repeat: no-repeat;
  // background-origin: border-box;
  // border: 2.5px solid var(--primary-color);
}

.property-unselected {
  --grad: transparent 49%, var(--primary-color) 52%;
  background: linear-gradient(to top left, var(--grad)) top left;
}

.bribe-unSelected,
.certificate-unSelected,
.property-unselected {
  color: #aa9b46 !important;
  font-weight: 600;
  background-size: 15px 14px; /* 10px of the clip-path + 3px of border */
  background-repeat: no-repeat;
  background-origin: border-box;
  border: 2.5px solid var(--primary-color);
}

.inventory-tab-mid {
  &.certificate-unSelected {
    background: none;
    border-left: 0;
    border-right: 0;
  }
}

.owned-nfts,
.show-skeleton-inventory {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 22px;
}
.no-nft-found {
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
}

@include max-break-point(mobile-phone) {
  .tab-inventory {
    margin-left: -25px !important;
  }
}

.property-card-wrapper {
  border: 0.5px solid #c68f25;
  width: 243.04px;
  cursor: pointer;

  .property-img-wrapper {
    width: 100%;
    height: 234px;
    background-image: url('../../../images/extra/property_card.svg');
    background-repeat: no-repeat;

    .property-img {
      width: 227px;
      height: 276px;
      margin: -20px 0 0 8px;
      object-fit: contain;
    }
  }
  .card-box-part {
    .card-decor-box {
      .card-type {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .card-info-content {
          display: flex;
          flex-direction: column;
          gap: 3px;
        }
      }
    }

    .card-property-name-text {
      font: normal 400 16px/26px var(--manrope);
      color: var(--primary-color);
      letter-spacing: 0.5px;
    }

    .card-property-district-text {
      font: normal 400 14px var(--manrope);
      letter-spacing: 0.5px;
    }

    .card-zone-text,
    .inventory-type-num {
      font: normal 600 12px/16px var(--manrope);
    }

    .card-zone-text {
      letter-spacing: 0.004em;
      color: rgba(201, 204, 217, 0.6);
    }

    .inventory-type-num {
      font-weight: 700;
      letter-spacing: -0.0125em;
      text-transform: uppercase;
      color: #8d8d8d;
      margin-bottom: 10px;
    }
  }
}
