.reveal-loading-wrapper {
  --animation-duration: 600ms;

  min-height: 100vh;
  height: 100%;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.85);

  ::-moz-selection {
    color: #fff;
    background: var(--primary-color);
  }
  ::selection {
    color: #fff;
    background: var(--primary-color);
  }

  .reveal-loading-content {
    height: 220px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    &::before,
    &::after {
      content: '';
      display: inline-block;
      width: 50%;
      height: 100%;
      visibility: hidden;
      position: absolute;
      mix-blend-mode: normal;
      backdrop-filter: blur(1px);
    }

    &::before {
      background: linear-gradient(90deg, rgba(28, 36, 42, 0.54) 2.33%, rgba(32, 168, 133, 0.6) 70%);
      animation: slideFromLeft var(--animation-duration) 1 forwards;
    }

    &::after {
      background: linear-gradient(
        270deg,
        rgba(28, 36, 42, 0.54) 2.33%,
        rgba(32, 168, 133, 0.6) 70%
      );
      animation: slideFromRight var(--animation-duration) 1 forwards;
      right: 0;
    }

    .shimmer {
      animation: shimmerAnimation 1.8s linear infinite;
      animation-delay: var(--animation-duration);
    }

    .shimmer-wrapper {
      position: absolute;
      top: 2px;
      height: 100%;
      width: 100%;
      clip-path: polygon(
        10px 0%,
        calc(100% - 10px) 0%,
        100% 10px,
        100% calc(100% - 10px),
        calc(100% - 10px) 100%,
        10px 100%,
        0% calc(100% - 10px),
        0% 10px
      );
      z-index: 1;

      .shimmer {
        filter: blur(2px);
        background: #dfcd808c;

        &.big {
          position: absolute;
          left: -160px;
          width: 70px;
          height: inherit;
        }
        &.small {
          position: absolute;
          width: 30px;
          height: inherit;
          left: -70px;
        }
      }
    }

    .reveal-loading-overlay {
      border-image-slice: 18 0 18 0;
      border-image-width: 20px 0px 20px 0px;
      border-image-outset: 0px 0px 0px 0px;
      border-image-repeat: repeat stretch;
      border-image-source: url('../../../images/common/landRevealBorder.svg');
      height: calc(100% + 16px);
      width: 100%;
      display: flex;
      margin: 0;
      background: radial-gradient(
        circle at 50% 49%,
        rgba(10, 14, 16, 0) 59%,
        rgba(10, 14, 16, 0.4) 88%
      );
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -8px;
      z-index: 1;

      opacity: 0;
      animation: customFadeIn var(--animation-duration) 1 forwards;
      transform: translateY(20px);
    }

    .stylish-info-seperator {
      margin: 0 !important;
      @include max-break-point(tablet-landscape) {
        .border-bottom-main {
          margin-bottom: 2rem;
        }
      }

      .heading-name {
        font: normal 400 68px/82px var(--cinzel) !important;
        background: linear-gradient(180deg, #ddbf62 -7.14%, #887635 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @include max-break-point(tablet-landscape) {
          font-size: 30px !important;
          line-height: 40px !important;
        }
      }
    }
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
    visibility: visible;
  }
}

@keyframes slideFromRight {
  0% {
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    transform: translateX(0);
    visibility: visible;
  }
}

@keyframes customFadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shimmerAnimation {
  0% {
    transform: skewX(-20deg) translateX(-200px);
  }
  100% {
    transform: skewX(-20deg) translateX(calc(100vw + 200px));
  }
}
