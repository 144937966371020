.bg__image {
  background-image: url('../../../images/common/bg.png');
  position: fixed;
  top: 0px;
  bottom: 0px;
  filter: blur(1023px);
  width: 100%;
  background-color: rgba(32, 32, 32, 0.1);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
