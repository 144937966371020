.leaflet-map-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font: normal 600 12px/16px var(--manrope);
    letter-spacing: 0.004em;
  }

  .map-wrapper {
    height: 250px;
    width: 100%;
    position: relative;

    img {
      height: 100%;
      object-fit: cover;
      object-position: 50% 38%;
    }

    .map-canvas {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      right: 0;
    }
  }

  .ripple-container .box {
    width: 8px;
    height: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ripple-container .box span {
    position: absolute;
    box-sizing: border-box;
    border-radius: 50%;
    animation: ripple 2s linear infinite;
    animation-delay: calc(-0.5s * var(--i));
    background: #aa9b469c;
  }
}
@keyframes ripple {
  0% {
    width: 0px;
    height: 0px;
  }
  50% {
    opacity: 1;
  }
  100% {
    width: 30px;
    height: 30px;
    opacity: 0;
  }
}
