.wallet__background {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(24px);
  // max-width: 90%;

  .wallet__img {
    width: 180px;
    display: block;
    margin: auto;
  }

  .wallet__message {
    text-align: center;
    margin: 16px 0;

    .connect_btn {
      // div {
      button {
        width: 301px;
      }
      // }
    }

    a {
      text-decoration: none;
      color: var(--primary-color);
    }
  }
}

.connect__wallet__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.connect-wallet-seperator {
  width: 70% !important;
}
@include max-break-point(tablet-landscape) {
  .connect-wallet-seperator {
    width: 70% !important;
  }
}
@include max-break-point(tablet) {
  .connect-wallet-seperator {
    width: 85% !important;
  }
}
@include max-break-point(mobile-phone) {
  .wallet__message {
    .connect_btn {
      button {
        width: 240px;
      }
    }
  }

  .wallet__background {
    .decor-content {
      margin: 25px 10px;
    }
  }

  .connect-wallet-seperator {
    width: 75% !important;
  }
}
