.carousel-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  .carousel-text {
    text-align: center;
    .carousel-heading {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.15px;
      color: #e5e8eb;
    }
    .carousel-paragraph {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 160%;
      letter-spacing: 0.5px;
    }
  }
  .carousel-image-div {
    position: relative;
    .carousel-next-left {
      position: absolute;
      left: 0;
      top: 45%;
      cursor: pointer;
    }
    .carousel-next-right {
      position: absolute;
      cursor: pointer;
      top: 45%;
      transform: rotate(180deg);
    }
    .carousle-selected-image {
      margin-top: -40px;
      margin-left: 40px;
      cursor: pointer;
    }
  }
  .image-diff-view-wrapper {
    display: flex;
    gap: 10px;
    margin: -80px 0px 50px 80px;

    .image-diff-view {
      height: 70px;
      margin-top: 10px;
      width: 60px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 20px;
      cursor: pointer;
      .diff-view {
        padding: 5px;
        height: 100%;
      }
    }
  }
  .selected-carousel {
    border: 1px solid var(--primary-color);
    border-radius: 15px;
  }
}

.carousel-selected-skeleton {
  margin-top: 50px;
  width: 300px;
  min-width: 300px;
  height: 270px;
  margin-bottom: 130px;
  border-radius: 15px;
  margin-left: 10px;
}

.set-image-top {
  z-index: 1;
}

.diff-view-skeleton {
  padding: 5px;
  height: 100%;
  border-radius: 15px;
}

.land-viewer-mansion {
  padding: 10px;
}

@include max-break-point(mobile-phone) {
  .image-diff-view-wrapper {
    margin: -80px 0px 50px 25px !important;
  }
  .carousle-selected-image {
    width: 300px !important;
    height: 470px !important;
  }
}
