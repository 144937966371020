.gangster-info-wrapper {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(24px);
  .gangster-info-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
  .gangster-texts {
    display: flex;
    margin-top: 25px;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    .gangster-info-text {
      padding: 0px 60px;
      font-weight: 300;
      font-size: 15px;
      line-height: 22.4px;
      color: #c9ccd9;
      letter-spacing: 0.65px;
    }
  }
}

@include max-break-point(tablet-landscape) {
}
@include max-break-point(tablet) {
  .gangster-info-text {
    padding: 0px 10px !important;
  }
}
@include max-break-point(mobile-phone) {
}
