.property-img-carousel-wrapper {
  &.carousel-wrapper {
    margin: 0;
  }

  .spotlight-img {
    position: absolute;
    z-index: -1;
    background: url('../../../images/common/spotlight.png') no-repeat fixed center;
    animation: spin 10s linear infinite;
    -webkit-animation: spin 10s linear infinite;
    -moz-animation: spin 10s linear infinite;
    background-size: 0;

    @include max-break-point(mobile-phone) {
      bottom: 630px;
    }
  }

  .property-img {
    margin-left: -55px;

    .skeleton {
      position: relative;
      z-index: 0;
    }
  }

  .carousel-selected-img {
    width: 650px;
    height: 600px;
    object-fit: contain;
    max-width: 100%;
    margin: -55px 0px 0 55px;

    @include max-break-point(tablet) {
      margin-left: 30px;
    }
  }

  .image-diff-view-wrapper {
    margin-top: -66px;
    margin-left: 0px;
    margin-bottom: 15px;
    justify-content: center;
    flex-wrap: wrap;
    z-index: 3;

    position: relative;

    .image-diff-view {
      width: 90px;
      height: 85px;
      background: rgba(71, 71, 71, 0.4);

      img {
        object-fit: contain;
      }
    }
  }
}
