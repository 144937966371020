.property-profile-page {
  .property-profile-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 48px;
  }

  .profile-left {
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;

    @include max-break-point(mobile-phone) {
      gap: 0;
    }

    .property-info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      margin-top: -30px;

      .property-btns {
        display: flex;
        flex-direction: row;
        gap: 18px;

        .primary {
          background: none;
        }

        .diff-viewer-btn {
          width: 67px;
          height: 53px;

          &.disabled {
            img {
              filter: brightness(0.8) saturate(0) !important;
            }
          }

          .button {
            width: 100%;
            height: 100%;

            img {
              width: 22px;
              height: 22px;
              filter: brightness(0);
            }
          }
        }
      }

      .ipfs-link {
        font: normal 500 12px/16px var(--manrope);
        letter-spacing: 0.004em;
        text-decoration: none;
      }

      .profile-owner {
        position: relative;
        font-weight: 500;
        font-size: 12px;
        color: var(--white);
        cursor: pointer;
        background-color: transparent;
        border: none;

        &.show-info::before {
          position: absolute;
          content: 'Copied!';
          bottom: -6px;
          right: -58px;
          background: rgba(0, 0, 0, 0.6);
          color: var(--primary-color);
          padding: 6px;
          border-radius: 4px;
          z-index: 100;
          border: 2px solid var(--primary-color);
        }
      }
    }
  }

  .profile-right {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 50%;

    @include max-break-point(tablet) {
      width: 100%;
    }

    @include max-break-point(mobile-phone) {
      width: 100%;
    }
    .location-bg {
      background: rgba(0, 0, 0, 0.6);
      backdrop-filter: blur(24px);
    }
    .property-detail-content {
      .property-location-info {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 13px;

        .property-name {
          color: #aa9b46;
          text-align: center;

          font-family: var(--manrope);
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 160%; /* 28.8px */
          letter-spacing: 0.15px;
        }

        .metadata-link {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          flex-wrap: nowrap;
          gap: 10px;
          text-decoration: none;
          font: normal 400 12px/16px var(--manrope);
        }
      }
    }
  }
}
