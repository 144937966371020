.congrat-carousel {
  overflow: visible;

  .property-detail-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;

    .left-section {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include max-break-point(mobile-phone) {
        .detail-link-wrapper {
          margin-top: -25px;
        }
      }

      .property-detail-link {
        text-decoration: none;
        font: nomal 600 12px/16ppx var(--manrope);
        letter-spacing: 0.004em;
      }
    }

    .right-section {
      // width: 100%;
      display: flex;
      justify-content: center;
    }

    .property-detail-content {
      width: 497px;

      @include max-break-point(tablet) {
        width: 100% !important;
      }
      .property-name {
        font: normal 700 20px/32px var(--manrope);
        letter-spacing: 0.15px;
        color: #e5e8eb;
        text-align: center;
      }
    }

    .property-description {
      font: normal 400 14px/22px var(--manrope);
      letter-spacing: 0.0025em;
      color: #e5e8eb;
      white-space: normal;
      text-align: center;
    }
  }

  .carousel-controls {
    top: 30.5%;
    z-index: 98;
  }

  @include max-break-point(mobile-phone) {
    .carousel-controls {
      top: 24.5%;
    }
  }
}
