.profile-wrapper {
  display: flex;
  gap: 9%;
  .profile-left-back {
    flex: 0.5;
  }
  .profile-left-certificate {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    .profile_owner_info {
      font-weight: 500;
      font-size: 12px;
      color: var(--primary-color);
      cursor: pointer;
      background-color: transparent;
      border: none;
    }
  }
  .profile-right {
    flex: 2.2;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.opaque {
  opacity: 1 !important;
}

@include max-break-point(tablet-landscape) {
  .profile-wrapper {
    flex-direction: column;
    gap: 30px;
  }
}
@include max-break-point(tablet) {
}
@include max-break-point(mobile-phone) {
}
