.button-component {
  position: relative;
  display: inline;
  cursor: pointer;
}

.button-component:hover .shimmer {
  transition: all 0.5s linear;
  transform: skewX(-20deg) translateX(490px);
}

.shimmer-wrapper {
  position: absolute;
  top: 20px;
  height: 53px;
  width: 100%;
  clip-path: polygon(
    10px 0%,
    calc(100% - 10px) 0%,
    100% 10px,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    10px 100%,
    0% calc(100% - 10px),
    0% 10px
  );
  z-index: 1;

  .shimmer {
    transform: skewX(-20deg);
    background: #dfcd80;

    &.big {
      position: absolute;
      left: -80px;
      width: 35px;
      height: inherit;
    }
    &.small {
      position: absolute;
      width: 10px;
      height: inherit;
      left: -35px;
    }
  }
}

.button {
  height: 53px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 16px;
  color: #1d1b21;
  font-family: var(--manrope);
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  clip-path: polygon(
    10px 0%,
    calc(100% - 10px) 0%,
    100% 10px,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    10px 100%,
    0% calc(100% - 10px),
    0% 10px
  );

  // &:hover {
  //   animation: jigglewscale 0.5s infinite linear;
  // }

  // @keyframes jigglewscale {
  //   0% {
  //     transform: rotate(0deg);
  //   }
  //   50% {
  //     transform: rotate(0.05deg);
  //   }
  //   100% {
  //     transform: rotate(-0.05deg);
  //   }
  // }
}

.primary {
  background: var(--primary-color);
  display: inline;
  .button {
    background: linear-gradient(180deg, #ddbf62 -7.14%, #887635 100%);
    border: none;
  }
  &.disabled {
    background: none;
    .button {
      span,
      svg {
        color: rgb(100, 96, 96);
        font-weight: 600;
      }
      --grad: transparent 49%, rgb(100, 96, 96) 52%;
      background: linear-gradient(to top right, var(--grad)) top right,
        linear-gradient(to top left, var(--grad)) top left,
        linear-gradient(to bottom right, var(--grad)) bottom right,
        linear-gradient(to bottom left, var(--grad)) bottom left;
      background-size: 15px 14px; /* 10px of the clip-path + 3px of border */
      background-repeat: no-repeat;
      background-origin: border-box;
      // cursor: pointer;
      border: 2.5px solid rgb(100, 96, 96);
    }

    .button:hover {
      cursor: not-allowed;
    }
  }
}

.disable-buttons {
  cursor: not-allowed;
}
.disabled-buttons {
  cursor: not-allowed;
  background: #373938 !important;
  color: #5b5f65;
}

.primary-outline {
  .button {
    span {
      color: var(--primary-color);
      font-weight: 600;
    }
    --grad: transparent 49%, var(--primary-color) 52%;
    background: linear-gradient(to top right, var(--grad)) top right,
      linear-gradient(to top left, var(--grad)) top left,
      linear-gradient(to bottom right, var(--grad)) bottom right,
      linear-gradient(to bottom left, var(--grad)) bottom left, transparent;
    background-size: 15px 14px; /* 10px of the clip-path + 3px of border */
    background-repeat: no-repeat;
    background-origin: border-box;
    // cursor: pointer;
    border: 2.5px solid var(--primary-color);
  }

  &.disabled {
    .button {
      span,
      svg {
        color: rgb(100, 96, 96);
        font-weight: 600;
      }
      --grad: transparent 49%, rgb(100, 96, 96) 52%;
      background: linear-gradient(to top right, var(--grad)) top right,
        linear-gradient(to top left, var(--grad)) top left,
        linear-gradient(to bottom right, var(--grad)) bottom right,
        linear-gradient(to bottom left, var(--grad)) bottom left, transparent;
      background-size: 15px 14px; /* 10px of the clip-path + 3px of border */
      background-repeat: no-repeat;
      background-origin: border-box;
      // cursor: pointer;
      border: 2.5px solid rgb(100, 96, 96);
    }

    .button:hover {
      cursor: not-allowed;
    }
  }
}

.spinner__wrapper {
  text-align: center;
}
