.congratulation-wrapper {
  position: relative;

  .nft__receive__message {
    font-size: 17px;
    letter-spacing: 0.3px;
  }
}

.congrats-cards {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  // position: relative;
  // overflow: hidden;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotate;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
}

.congratulation-btns {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.congrats-cards::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('../../../images/common/spotlight.png') no-repeat fixed center;
  -webkit-animation: spin 8s linear infinite;
  -moz-animation: spin 8s linear infinite;
  animation: spin 8s linear infinite;
  top: 0%;
  left: 0%;
  z-index: -1;
  background-size: 50%;

  @include max-break-point(tablet) {
    background-size: 100%;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
