.bank__main__background {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(24px);
}
.bank__info_text {
  font-size: 12px;
}
.bank-card {
  width: 843px;
  margin: auto;

  .bank__main {
    display: flex;
    flex-direction: row;
    margin-top: 65px;
  }

  .bank__image {
    flex: 2;

    // gap: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      max-width: 420px;
      margin-top: -26%;
      cursor: pointer;
    }
    .bank-landViewer-div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -15%;
      gap: 30px;
      .bank__image_bottom_text {
        a {
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: #068385;
          text-decoration: none;
        }
      }
    }
  }

  .bank__details {
    flex: 1;
    text-align: center;
    line-height: 25.6px;

    strong:nth-child(1),
    strong:nth-child(3) {
      font-size: 14px;
    }

    .crown__ratio {
      letter-spacing: 1px;
    }

    .crown__ratio__value {
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .bank__info_text {
      font-weight: 200;
      font-size: 14px;
      letter-spacing: 0.6px;
      margin-bottom: 16px;
    }
  }
}
.bank-btns {
  width: inherit;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.bank__deposit__box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .bank__deposit_info {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .bank__deposit__crown {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    img {
      width: 15px;
    }
    .bank__crown__deposit_amount {
      color: #079b9d;
    }
  }
  .total_earning {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    .total_earning_text {
      font-size: 14px;
    }
    .total_earning_amt {
      cursor: pointer;
      color: #079b9d;
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      letter-spacing: 0.45px;
    }
    img {
      width: 14px;
    }
    .earning_uint {
      font-size: 14px;
    }
  }
  .bank__deposit__xcrown {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    img {
      width: 15px;
    }
    .bank__xcrown__deposit_amount {
      color: #079b9d;
    }
  }
}

@include max-break-point(tablet-landscape) {
  .bank-card {
    width: 100%;
  }
}
@include max-break-point(tablet) {
  .bank__image {
    gap: 15px !important;
  }
  .bank__main {
    flex-direction: column !important;
    gap: 30px;
  }
}

.bank__text__extra {
  text-align: center;
  margin: 20px 0;
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 12px;
}

//land viewer css
.Modal {
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
canvas {
  width: 70vw;
  height: 90vh;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  right: 0;
  z-index: 20;
  bottom: 0;
  background: rgba(0, 0, 0, 0.32);
}
.loading-overlay {
  /* We'll render the overlay on top of the Unity Application. */
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
  width: 100%;
  height: 100%;
  background: #c1dbe3;
  /* We'll set the following Flex properties in order to center the text. */
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.click-disable {
  color: silver !important;
  cursor: default !important;
}

#loadingGIF {
  width: 50vw;
  height: 70vh;
  border-radius: 14px 14px 0px 0px;
}
.loading-percentage-div {
  margin-top: -20px !important;
  color: white;
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  .loading-percentage {
    margin-right: 30px;
  }
}

.modal-close-btn {
  color: black;
  position: absolute;
  z-index: 9;
}

.crown__tx__history {
  color: var(--primary-color);
  font-size: 14px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.crown__tx__history:hover {
  cursor: pointer;
}

.bank__breakdown {
  b {
    text-align: center;
    display: block;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  .individual__breakdown {
    display: flex;
    margin: 10px;
    padding: 4px 10px;

    div:nth-child(1) {
      text-align: left;
      flex: 1;
    }

    div:nth-child(2) {
      text-align: right;
      flex: 1;
    }

    div:nth-child(3) {
      text-align: right;
      flex: 1;
    }

    &:nth-child(2n) {
      background-color: rgba(170, 155, 70, 0.1);
    }
  }
}
.user-available-crown {
  cursor: pointer;
  font-weight: 800;
  background-color: transparent;
  outline: none;
  color: var(--primary-color);
  border: none;
}
//bank transaction history
.txHistoryPagination {
  display: flex;
  justify-content: center;
  gap: 20px;
  .tx-next-page,
  .tx-previous-page {
    cursor: pointer;
  }
  .disable-previous-page,
  .disable-next-page {
    cursor: not-allowed;
    color: grey;
  }
}

@include max-break-point(mobile-phone) {
  .bank__image {
    img {
      max-width: 420px;
      margin-top: -100px !important;
    }
    .bank-landViewer-div {
      margin-top: -25% !important;
    }
  }
}

.disabled:hover {
  cursor: not-allowed !important;
}

@include max-break-point(mobile-phone) {
  canvas {
    width: 98vw;
    height: 95vh;
  }
}
