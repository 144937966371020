.lp__stake__decor_combo {
  display: flex;
  gap: 20px;
  width: 80%;
  margin: 20px auto;

  .GBET__pool__decor {
    width: 50%;
  }

  .CROWN__pool__decor {
    width: 50%;
  }

  .pool__details {
    display: flex;
    flex-direction: row;
    flex: 3;

    .pool__picture {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .pool__name {
        letter-spacing: 0.15px;
      }
    }

    .APR__area {
      span {
        color: var(--primary-color);
        font-size: 14px;
      }
      background-color: #0f0f0f;
      padding: 10px;
      min-width: 90px;
      text-align: center;
      line-height: 1em;
    }
  }

  .stake__info {
    line-height: 2.5em;

    .info__title {
      color: var(--primary-color);
      font-size: 16px;
    }

    .info__row {
      display: flex;
      justify-content: space-between;
    }
  }

  .button__group {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .button-component {
      flex: 1;
    }
  }

  .ext__link {
    margin: 10px 0;
    text-align: center;
    color: var(--primary-color);

    a {
      text-decoration: none;
      color: var(--primary-color);
    }
  }
}

.roi-calculator-wrapper {
  .lp-staking-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    text-align: center;
    letter-spacing: 0.0025em;
    color: #c9ccd9;
  }
  .lp-amount-input-div {
    display: flex;
    margin-top: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .lp-amount-text {
      align-self: flex-start;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 5px;
      line-height: 16px;
      letter-spacing: 0.004em;
      color: #c9ccd9;
    }
    .lp-stake-amount-div {
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .lp-stake-enter-amount {
        width: 206px;
        background: none;
        border: 1px solid #ddbf62;
        color: #e5e8eb;
        height: 42px;
        font-weight: 700;
        font-size: 16px;
        text-align: right;
        padding: 3px;
        &:focus {
          outline: none;
        }
      }
      .total-lp {
        font-weight: 700;
        font-size: 16px;
        line-height: 160%;
        text-align: center;
        letter-spacing: 0.5px;
        color: rgba(229, 232, 235, 0.6);
      }
    }
    .lp-stake-recommended-percentage {
      display: flex;
      gap: 5px;

      .recommended-percentage {
        color: #ddbf62;
        border: 1px solid #ddbf62;
        padding: 0px 10px;
        font-size: 12px;
        margin-top: 7px;
      }
    }
  }
  .lp-stake-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }
}
