.footer-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding: 20px 5px;

  .footer-copyright {
    color: var(--primary-color);
    letter-spacing: 0.4px;
  }

  .footer-media {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: hsl(229, 17%, 82%);
  }

  .footer-media {
    *:hover {
      color: var(--primary-blue);
    }
  }
}
