.error404__background {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(24px);
  width: 600px !important;
  .error404__img {
    width: 180px;
    display: block;
    margin: auto;
  }

  .error404__message {
    text-align: center;
    margin: 16px 0;
    p {
      font-size: 20px;
    }
  }
}
.error__404__wrapper {
  display: flex;
  height: 70vh;
  justify-content: center;
  align-items: center;
}

.error__404__wrapper_not_found {
  display: flex;
  height: 50vh;
  justify-content: center;
  align-items: center;
}

.error-heading-sep {
  margin-top: 40px !important;
  p {
    font-size: 80px;
    font-weight: 900 !important;
  }
}
@include max-break-point(tablet) {
  .error-heading-sep {
    width: 70% !important;
    margin-top: 40px !important;
  }
}

@include max-break-point(mobile-phone) {
  .error-heading-sep {
    width: 80% !important;
    margin-top: 40px !important;
    p {
      font-size: 60px !important;
      font-weight: 900 !important;
    }
  }
  .error404__background {
    width: 95vw !important;
  }

  .error404__message {
    button {
      width: 250px !important;
    }
  }
}
