.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin: 40px 0px !important;
  .active {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }
}

.page-num {
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 5px 12px;
  border-radius: 1px;
  border: 1px solid grey;
}

@include max-break-point(tablet-landscape) {
}
@include max-break-point(tablet) {
}
@include max-break-point(mobile-phone) {
  .marketplace-lands {
    justify-content: center;
  }
  .pagination {
    gap: 5px;
  }
}
