.mint-status-wrapper {
  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  padding: 86px 150px;

  @include max-break-point(mobile-phone) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .decor-content {
    margin: 0 !important;
  }

  .mint-status-inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .counter-wrapper {
    padding: 12px 24px;
    border-radius: 8px;
    background: rgba(71, 71, 71, 0.4);
    color: #aa9b46;
    text-align: center;
    font: normal 700 20px/32px var(--manrope);
    letter-spacing: 0.15px;
  }

  .medium-link {
    color: #068385;
    font-family: var(--manrope);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.048px;
    text-decoration-line: underline;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
  }
}
