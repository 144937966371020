.emerald-feature-wrapper {
  background: #00000099;
  backdrop-filter: blur(24px);
  width: 75% !important;
  margin: 100px auto;
  // padding: 20px !important;
  .emerald-feature-info-top {
    margin: 20px 0px;
    text-align: center;
  }
  .emerald-features {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .feature-info {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

.mansion-feature-info-p {
  font-weight: 300;
  font-size: 14px;
  line-height: 22.4px;
  color: #c9ccd9;
  letter-spacing: 0.65px;
}

.feature-info-title {
  color: #aa9b46;
  font-weight: 600;
  font-size: 18px;
}

@include max-break-point(tablet-landscape) {
}
@include max-break-point(tablet) {
  .emerald-feature-wrapper {
    width: 100% !important;
    padding: 2px !important;
    .emerald-feature-info-top {
      padding: 5px !important;
    }
  }
  .emerald-features {
    gap: 10px !important;
    .feature-info {
      gap: 10px !important;
    }
  }
}
@include max-break-point(mobile-phone) {
}

.grayScaleTheText {
  filter: grayscale(100%) !important;
}
