.notification-container {
  box-sizing: border-box;
  position: fixed;
  z-index: 25;
}

.top-right {
  top: 0.75rem;
  right: 0.75rem;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.bottom-right {
  bottom: 0.75rem;
  right: 0.75rem;
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
}

.top-left {
  top: 0.75rem;
  left: 0.75rem;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.bottom-left {
  bottom: 0.75rem;
  left: 0.75rem;
  transition: transform 0.6s ease-in;
  animation: toast-in-left 0.7s;
}

.notification {
  &.error,
  &.normal,
  &.normal-with-btns {
    background: #171717;
    border: 0.0625rem solid var(--primary-color);
  }
  &.success,
  &.warn {
    background: #171717;
    border: 0.0625rem solid var(--primary-color);
  }

  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  padding: 1.125rem;
  gap: 0.75rem;
  // border-radius: 1rem;
  color: #ffffff;
  opacity: 0.98;
  margin-top: 0.9375rem;

  background-repeat: no-repeat;
}

.notification:hover {
  opacity: 1;
  cursor: pointer;
}

.notification-title {
  color: var(--primary-color);
  text-align: left;
  margin: 0;
  margin-bottom: 0.625rem;
}

.notification-message {
  margin: 0;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;
}
.toaster-body {
  display: flex;
  align-items: flex-start;
}
.notification-image {
  margin-top: 0.1875rem;
  margin-right: 0.5rem;
}

.notification-image img {
  width: 1.375rem;
  height: 1.375rem;
}

.toast {
  width: 25rem;
}

.notification-container button.close-toaster {
  position: relative;
  right: -0.25rem;
  top: 0.28rem;
  float: right;
  font-weight: 400;
  color: #bebebe;
  outline: none;
  border: none;
  text-shadow: 0 0.0625rem 0 #fff;
  opacity: 0.8;
  line-height: 1;
  font-size: 0.75rem;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@include max-break-point(mobile-phone) {
  .toast {
    width: 20rem !important;
  }
}
