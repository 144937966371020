.carousel-wrapper {
  overflow: hidden;
  margin: 0px auto;
  @include max-break-point(tablet) {
    width: 90% !important;
  }

  @include max-break-point(mobile-phone) {
    width: 95% !important;
  }

  .carousel-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    white-space: nowrap;
    margin-top: 15px;
    justify-content: center;
    align-items: center;

    .land-link {
      font: normal 600 14px/22px var(--manrope);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }

    .carousel-items {
      transition: transform 300ms;
    }
  }
}
