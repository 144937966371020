.reveal-property-wrapper {
  margin: 56px 80px 100px;
  // min-height: calc(100vh - 71px);

  @include max-break-point(mobile-phone) {
    margin-left: 0;
    margin-right: 0;
  }

  .stylish-info-separator {
    width: 60% !important;
    margin-bottom: 0px !important;
  }

  .reveal-property-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 38px;

    .general-certificate-img-wrapper {
      img {
        width: 420px;

        @include max-break-point(mobile-phone) {
          width: 320px;
        }
      }
    }
  }
}
